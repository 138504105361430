import { call, put } from "redux-saga/effects";
import { setSingle, purgeSingleState } from "../../slices/single";
import requestgetSingle from "../request/single"
import errors from "src/redux/saga/errors";

export function* handleSingle(action) {
    try {
        yield put(purgeSingleState());
        const { payload } = action;
        const response = yield call(requestgetSingle, payload);
        const { data } = response;        
        yield put(setSingle({post: data}));
        // if(data.pagename == 'user' && data.pagename == 'user'){
        //     data.items.map(() => {

        //     });
        //     localStorage.setItem('username', data.username);
        //     localStorage.setItem('email', data.email);
        //     localStorage.setItem('role', data.role);
        //     localStorage.setItem('avatar', data.avatar);
        //     localStorage.setItem('name', data.name);            
        // }
    } catch (error){
        errors(error);
        console.log(error);        
    }
}
