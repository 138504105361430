import { takeLatest } from "redux-saga/effects";
import { handleListing } from "./handlers/listing";
import { getListing } from "../slices/listing";
import { handleSingle } from "./handlers/single";
import { getSingle } from "../slices/single";
import { handleUpdateSingle } from "./handlers/updatesingle";
import { handleCreateSingle } from "./handlers/createsingle";
import { updateSingle, createSingle, copyColumns } from "../slices/single";
// import { getPostlist } from "../slices/postlist";
// import { handlePostlist } from "./handlers/postlist";
// import { getPostlistvalue } from "../slices/postlistvalue";
import { getPropertyvalue, updatePropertyvalue, createPropertyvalue } from "../slices/property";
import { handleGetPropertyvalue } from "./handlers/getpropertyvalue";
import { handleUpdatePropertyvalue } from "./handlers/updatepropertyvalue";
import { handleCreatePropertyvalue } from "./handlers/createpropertyvalue";

import { getProductitem, updateProductitem, createProductitem } from "../slices/product";
import { handleGetProductitem } from "./handlers/getproductitem";
import { handleUpdateProductitem } from "./handlers/updateproductitem";
import { handleCreateProductitem } from "./handlers/createproductitem";

import { getItem, updateItem, createItem } from "../slices/item";
import { handleGetItem } from "./handlers/getitem";
import { handleUpdateItem } from "./handlers/updateitem";
import { handleCreateItem } from "./handlers/createitem";

// import { handlePostlistvalue } from "./handlers/postlistvalue";
// import { createPostlistvalue } from "../slices/postlistvalue";
import { handlePostlistCreatevalue } from "./handlers/createpostlistvalue";
import { handleSlider } from "./handlers/slider";
import { createSlide, updateSlide, getSlide } from "../slices/slider";
import { handleCreateSlider } from "./handlers/createslider";
import { handleUpdateSlider } from "./handlers/updateslider";
import { handleCopyColumns } from "./handlers/copycolumns";
import { addBlock, saveBlock } from "../slices/visual";
import { handleCreateBlock } from "./handlers/createblock";
import { handleSaveBlock } from "./handlers/saveblock";
import { addField, getField, saveField, getOptions, addFieldOptions, deleteOption } from "../slices/formfield";
import { handleCreateFormfield } from "./handlers/createformfield";
import { handleGetFormfield } from "./handlers/getformfield";
import { handleUpdateFormfield } from "./handlers/updateformfield";
import { handleGetFormfieldOptions } from "./handlers/getoptions";
import { handleCreateFormfieldOptions } from "./handlers/createoptions";
import { handleDeleteFormfieldOptions } from "./handlers/deleteoptions";
import { addFormCsvField, updateFormCsvField } from "../slices/formcsv";
import { handleCreateCsvfield } from "./handlers/createcsvfield";
import { handleUpdateCsvfield } from "./handlers/updatecsvfield";

export function* watcherSaga() {
    yield takeLatest(getListing.type, handleListing);
    yield takeLatest(getSingle.type, handleSingle);
    yield takeLatest(updateSingle.type, handleUpdateSingle);
    yield takeLatest(createSingle.type, handleCreateSingle);
    yield takeLatest(getPropertyvalue.type, handleGetPropertyvalue);
    yield takeLatest(updatePropertyvalue.type, handleUpdatePropertyvalue);
    yield takeLatest(createPropertyvalue.type, handleCreatePropertyvalue);

    yield takeLatest(getProductitem.type, handleGetProductitem);
    yield takeLatest(updateProductitem.type, handleUpdateProductitem);
    yield takeLatest(createProductitem.type, handleCreateProductitem);

    yield takeLatest(getItem.type, handleGetItem);
    yield takeLatest(updateItem.type, handleUpdateItem);
    yield takeLatest(createItem.type, handleCreateItem);

    // yield takeLatest(getPostlist.type, handlePostlist);
    // yield takeLatest(getPostlistvalue.type, handlePostlistvalue);
    // yield takeLatest(createPostlistvalue.type, handlePostlistCreatevalue);
    yield takeLatest(getSlide.type, handleSlider);
    yield takeLatest(createSlide.type, handleCreateSlider);
    yield takeLatest(updateSlide.type, handleUpdateSlider);
    yield takeLatest(copyColumns.type, handleCopyColumns);
    yield takeLatest(addBlock.type, handleCreateBlock);
    yield takeLatest(saveBlock.type, handleSaveBlock);
    yield takeLatest(addField.type, handleCreateFormfield);
    yield takeLatest(getField.type, handleGetFormfield);
    yield takeLatest(saveField.type, handleUpdateFormfield);
    yield takeLatest(getOptions.type, handleGetFormfieldOptions);
    yield takeLatest(addFieldOptions.type, handleCreateFormfieldOptions);
    yield takeLatest(deleteOption.type, handleDeleteFormfieldOptions);
    yield takeLatest(addFormCsvField.type, handleCreateCsvfield);
    yield takeLatest(updateFormCsvField.type, handleUpdateCsvfield);
};
