import clsx from 'clsx';
import { Icon } from '@iconify/react';
import React, { useRef, useState } from 'react';
import homeFill from '@iconify-icons/eva/home-fill';
import PopoverMenu from 'src/components/PopoverMenu';
import { Link as RouterLink } from 'react-router-dom';
import personFill from '@iconify-icons/eva/person-fill';
import settings2Fill from '@iconify-icons/eva/settings-2-fill';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { logoutUser } from '../../../redux/saga/request/logout';
import { useSelector, useDispatch } from 'react-redux';
import history from "src/routes/history";
import {
  Box,
  Avatar,
  Button,
  Divider,
  MenuItem,
  Typography
} from '@material-ui/core';
import { MIconButton } from 'src/theme';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  menuItem: {
    ...theme.typography.body2,
    padding: theme.spacing(1, 2.5)
  },
  btnAvatar: {
    padding: 0,
    width: 44,
    height: 44
  },
  isSelected: {
    '&:before': {
      zIndex: 1,
      content: "''",
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      background: alpha(theme.palette.grey[900], 0.8)
    }
  }
}));

// ----------------------------------------------------------------------

function Account() {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const { role, username, avatar, id } = useSelector((state) => state.login);

  const MENU_OPTIONS = [
    {
      label: 'Home',
      icon: homeFill,
      linkTo: '/'
    },
    {
      label: 'Profile',
      icon: personFill,
      linkTo: '/app/post/user/single/'+id
    },
    // {
    //   label: 'Settings',
    //   icon: settings2Fill,
    //   linkTo: '#'
    // }
  ];  

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event) => {
    setOpen(false);
    history.push(event);
  };

  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(logoutUser());
  };  

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        className={clsx(classes.btnAvatar, { [classes.isSelected]: isOpen })}
      >
        <Avatar
          alt="My Avatar"
          src={avatar ? process.env.REACT_APP_ASSETS_URL+avatar : ''}
        />
      </MIconButton>
      <PopoverMenu
        width={220}
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ my: 2, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {username}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {role}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            // to={option.linkTo}
            // component={RouterLink}
            onClick={() => handleClose(option.linkTo)}
            className={classes.menuItem}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button onClick={onSubmit} fullWidth color="inherit" variant="outlined">
            Logout
          </Button>
        </Box>
      </PopoverMenu>
    </>
  );
}

export default Account;
