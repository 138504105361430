import { createAsyncThunk } from '@reduxjs/toolkit';
import history from "src/routes/history";

export const logoutUser = createAsyncThunk(
    'users/logout',
    async (thunkAPI) => {
      localStorage.removeItem('email');
      localStorage.removeItem('username');
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('id');
      localStorage.removeItem('avatar');
      history.push('/auth/login');      
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL+'/auth/logout',
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.getItem('token') || null
            })
          }
        );        
        let data = 'await response.json()';
        // if (response.status === 200) {            
        //   return data;
        // } else {
        //   return thunkAPI.rejectWithValue(data);
        // }
      } catch (e) {
        console.log('Error', e.response.data);
        thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );